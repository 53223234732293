<script lang="ts">
export default defineComponent({
  name: 'SkrImage',
  props: {
    src: String,
    lazy: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      required: false,
    },
    emitSize: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { documentRepository } = useApi()
    const { getWebPDimensionsFromEXIF } = useExif()

    return { getWebPDimensionsFromEXIF, getDocumentPage: documentRepository.getDocumentPageByUrl }
  },
  data() {
    return {
      imgSrc: '',
      loaded: false,
      imgHeight: '',
      ready: false,
      loadTimeout: 0,
      buf: undefined as ArrayBuffer | undefined,
    }
  },
  watch: {
    height() {
      this.setHeight()
    },
    /* the two watchers below are because sometimes the parent
      component updates these values slowly, and if the component
      has mounted then it might not trigger loading the image
      simply by scrolling into view */
    src(val, old) {
      if (val !== old) {
        const hasLoaded = this.loaded
        this.imgSrc = ''
        this.loaded = false
        this.imgHeight = ''
        this.ready = false
        this.loadTimeout = 0
        this.buf = undefined
        // if lazy, only load if it's in the viewport
        if (this.lazy && !hasLoaded) return
        void this.loadImg()
      }
    },
    lazy() {
      if (!this.lazy && !this.loaded) void this.loadImg()
    },
  },
  beforeMount() {
    this.setHeight()
  },
  mounted() {
    if (!this.lazy) void this.loadImg()
  },
  methods: {
    loadLazy(_: boolean, entries: [IntersectionObserverEntry]) {
      if (!entries[0]?.isIntersecting) {
        clearTimeout(this.loadTimeout)
        return
      }
      if (this.imgHeight && this.ready) {
        this.loadTimeout = window.setTimeout(() => {
          void this.loadImg()
        }, 1000)
      }
    },
    async loadImg() {
      if (this.imgSrc) return
      const src = this.src
      if (!src) return
      try {
        const blob = await this.getDocumentPage(src)
        const buf = await new Response(blob).arrayBuffer()
        this.buf = buf
        if (blob.type === 'image/webp' && !this.$webp.supported()) {
          this.imgSrc = await this.$webp.decode(new Uint8Array(buf))
        } else {
          this.imgSrc = URL.createObjectURL(blob)
        }
        this.imgHeight = ''
        // just wait for the src value to be set so there's a moment for the image to load
        this.loaded = true
      } catch {
        // silently fail
      }
    },
    getDimensions() {
      if (!this.buf) return
      const data: { width: number; height: number; exif?: { width: number; height: number } } = {
        width: -1,
        height: -1,
      }
      const exif = this.getWebPDimensionsFromEXIF(this.buf as ArrayBuffer)
      if (exif.width >= 0) {
        data.exif = exif
      }
      const img = this.$refs.image
      if (img && img instanceof HTMLImageElement) {
        data.width = img.naturalWidth
        data.height = img.naturalHeight
      }
      return data
    },
    setHeight() {
      /* visibility detection is super fragile, still not sure how to make
        this work reliably and be clear, but right now, checking the height
        prop seems to be necessary */
      if (!this.imgHeight && !this.loaded && this.height && this.height > 0) {
        this.imgHeight = `${this.height}px`
        // need to delay the load trigger to allow height to
        // take effect and prevent v-intersect from running
        setTimeout(() => {
          this.ready = true
          const inviewport = this.isInViewport()
          if (inviewport) {
            void this.loadImg()
          }
        }, 100)
      }
    },
    isInViewport() {
      const rect = this.$el.getBoundingClientRect()
      if (rect.height === 0) return false
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    onLoaded($event) {
      if (this.emitSize) {
        this.$emit('size', this.getDimensions())
      }
      this.$emit('load', $event)
    },
  },
})
</script>

<template>
  <img
    v-if="lazy"
    ref="image"
    v-intersect.quiet="loadLazy"
    class="skr-image skr-image__lazy"
    :src="imgSrc"
    :lazy="loaded ? 'loaded' : ''"
    :style="{ height: imgHeight !== '' ? imgHeight : 'auto' }"
    :data-src="src"
    alt=" "
    @load="onLoaded"
    @click="$emit('click', $event)"
  />
  <img
    v-else
    class="skr-image"
    ref="image"
    :src="imgSrc"
    :style="{ height: imgHeight !== '' ? imgHeight : 'auto' }"
    :data-src="src"
    alt=" "
    @load="onLoaded"
    @click="$emit('click', $event)"
  />
</template>

<style lang="sass">
$ns: skr-image

.#{$ns}
  pointer-events: none // Edge fix
  &[src='']
    visibility: hidden
    width: 0
  &__lazy
    &:not([lazy=loaded])
      visibility: hidden
      width: 0
</style>
